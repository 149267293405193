
import W from './W';
import Suffix from './Suffix';



import InfiniteScroll from 'react-infinite-scroll-component';

import VerseSelectModal from './VerseSelectModal';
import ChapterSelect from './ChapterSelect';
import './ntBook.css';
import { useEffect, useState } from 'react';


import Matthew from '../data/chapteredBooks/Matthew.json'
import Mark from '../data/chapteredBooks/Mark.json';
import Luke from '../data/chapteredBooks/Luke.json';
import John from '../data/chapteredBooks/John.json';
import Acts from '../data/chapteredBooks/Acts.json';
import Romans from '../data/chapteredBooks/Romans.json';
import FirstCorinthians from '../data/chapteredBooks/FirstCorinthians.json';
import SecondCorinthians from '../data/chapteredBooks/SecondCorinthians.json';
import Galatians from '../data/chapteredBooks/Galatians.json';
import Ephesians from '../data/chapteredBooks/Ephesians.json';
import Philippians from '../data/chapteredBooks/Philippians.json';
import Colossians from '../data/chapteredBooks/Colossians.json';
import FirstThessalonians from '../data/chapteredBooks/FirstThessalonians.json';
import SecondThessalonians from '../data/chapteredBooks/SecondThessalonians.json';
import FirstTimothy from '../data/chapteredBooks/FirstTimothy.json';
import SecondTimothy from '../data/chapteredBooks/SecondTimothy.json';
import Titus from '../data/chapteredBooks/Titus.json';
import Philemon from '../data/chapteredBooks/Philemon.json';
import Hebrews from '../data/chapteredBooks/Hebrews.json';
import James from '../data/chapteredBooks/James.json';
import FirstPeter from '../data/chapteredBooks/FirstPeter.json';
import SecondPeter from '../data/chapteredBooks/SecondPeter.json';
import FirstJohn from '../data/chapteredBooks/FirstJohn.json';
import SecondJohn from '../data/chapteredBooks/SecondJohn.json';
import ThirdJohn from '../data/chapteredBooks/ThirdJohn.json';
import Jude from '../data/chapteredBooks/Jude.json';
import Revelation from '../data/chapteredBooks/Revelation.json';



export default function NTBook(props: { book: string, callback: (bookName: string) => void; }) {


    const [chapterArray, setChapterArray] = useState([[]]);
    const [currentChapter, setCurrentChapter] = useState(0);//adjusted for index.



    useEffect(() => {

        //setCurrent(chapterArray.slice(count.prev, count.next));

    }, [chapterArray]);

    useEffect(() => {
        setChapterArray([[]]);
        setCurrentChapter(0);
        // props.callback(testData['title']);
        switch (props.book) {
            case 'Matthew':
                setChapterArray(Matthew['book']);
                props.callback(Matthew['title']);
                return;
            case 'Mark':
                setChapterArray(Mark['book']);
                props.callback(Mark['title']);
                return;
            case 'Luke':
                setChapterArray(Luke['book']);
                props.callback(Luke['title']);
                return;
            case 'John':
                setChapterArray(John['book']);
                props.callback(John['title']);
                return;
            case 'Acts':
                setChapterArray(Acts['book']);
                props.callback(Acts['title']);
                return;
            case 'Romans':
                setChapterArray(Romans['book']);
                props.callback(Romans['title']);
                return;
            case 'FirstCorinthians':
                setChapterArray(FirstCorinthians['book']);
                props.callback(FirstCorinthians['title']);
                return;
            case 'SecondCorinthians':
                setChapterArray(SecondCorinthians['book']);
                props.callback(SecondCorinthians['title']);
                return;
            case 'Galatians':
                setChapterArray(Galatians['book']);
                props.callback(Galatians['title']);
                return;
            case 'Ephesians':
                setChapterArray(Ephesians['book']);
                props.callback(Ephesians['title']);
                return;
            case 'Philippians':
                setChapterArray(Philippians['book']);
                props.callback(Philippians['title']);
                return;
            case 'Colossians':
                setChapterArray(Colossians['book']);
                props.callback(Colossians['title']);
                return;
            case 'FirstThessalonians':
                setChapterArray(FirstThessalonians['book']);
                props.callback(FirstThessalonians['title']);
                return;
            case 'SecondThessalonians':
                setChapterArray(SecondThessalonians['book']);
                props.callback(SecondThessalonians['title']);
                return;
            case 'FirstTimothy':
                setChapterArray(FirstTimothy['book']);
                props.callback(FirstTimothy['title']);
                return;
            case 'SecondTimothy':
                setChapterArray(SecondTimothy['book']);
                props.callback(SecondTimothy['title']);
                return;
            case 'Titus':
                setChapterArray(Titus['book']);
                props.callback(Titus['title']);
                return;
            case 'Philemon':
                setChapterArray(Philemon['book']);
                props.callback(Philemon['title']);
                return;
            case 'Hebrews':
                setChapterArray(Hebrews['book']);
                props.callback(Hebrews['title']);
                return;
            case 'James':
                setChapterArray(James['book']);
                props.callback(James['title']);
                return;
            case 'FirstPeter':
                setChapterArray(FirstPeter['book']);
                props.callback(FirstPeter['title']);
                return;
            case 'SecondPeter':
                setChapterArray(SecondPeter['book']);
                props.callback(SecondPeter['title']);
                return;
            case 'FirstJohn':
                setChapterArray(FirstJohn['book']);
                props.callback(FirstJohn['title']);
                return;
            case 'SecondJohn':
                setChapterArray(SecondJohn['book']);
                props.callback(SecondJohn['title']);
                return;
            case 'ThirdJohn':
                setChapterArray(ThirdJohn['book']);
                props.callback(ThirdJohn['title']);
                return;
            case 'Jude':
                setChapterArray(Jude['book']);
                props.callback(Jude['title']);
                return;
            case 'Revelation':
                setChapterArray(Revelation['book']);
                props.callback(Revelation['title']);
                return;
            default:
                setChapterArray([]);
                return;
        }
    }, [props.book]);

    


    const renderVerseSelected = (chapterVerseString: string) => {//{"verseNumber": chapterVerseString}

        //     const indexOfVerse = chapterArray.findIndex(element => element.verseNumber == chapterVerseString);


        //        setCurrent(chapterArray.slice(indexOfVerse, indexOfVerse + 1000));

    }

    return <>
        <ChapterSelect name={props.book} currentChapter={currentChapter} callback={setCurrentChapter} />

        {

            chapterArray[currentChapter].map((item) => {


                if ('verseNumber' in item) {
                    let digits = item.verseNumber.split(':');
                    return <span className="verseNumber" id={item.verseNumber}>{(digits[1] === '1' ? item.verseNumber : digits[1])}</span>;
                }
                else if ('lemma' in item && 'strongs' in item) {
                    return <W lemma={item.lemma} morph={item.morph} strongs={item.strongs} stripped={item.stripped} pos={item.pos}>{item.word}</W>;
                }
                else if ('lemma' in item) {
                    return <W lemma={item.lemma} morph={item.morph} stripped={item.stripped} pos={item.pos}>{item.word}</W>;
                }
                else if ('suffix' in item) {
                    if (item.suffix.indexOf('.') > -1) {
                        return <Suffix>. </Suffix>;
                    }
                    if (item.suffix.indexOf(',') > -1) {
                        return <Suffix>, </Suffix>;
                    }
                    if (item.suffix.indexOf('·') > -1) {
                        return <Suffix>· </Suffix>;
                    }
                    if (item.suffix.indexOf(';') > -1) {
                        return <Suffix>; </Suffix>;
                    }
                    //return <Suffix>{item.suffix}</Suffix>;
                }
                else if ('empty' in item) {
                    return <p></p>;
                }
                else { }


            })}
        <ChapterSelect name={props.book} currentChapter={currentChapter} callback={setCurrentChapter} />

    </>

}