import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useState } from 'react';
import { useParams } from 'react-router';


import HomePage from '../components/HomePage';


import './Page.css';
//import firebase from 'firebase';


import NTBook from '../components/NTBook';

/*const config = {
  apiKey: "AIzaSyCxuJ0jcQGjigI2Mzjxso71dTCO2ZnvLnw",
  databaseURL: "https://opengreeknt-default-rtdb.firebaseio.com"
};
firebase.initializeApp(config);*/
const Page: React.FC = () => {

  const [currentBook, setCurrentBook] = useState('');


  const { name } = useParams<{ name: string; }>();
  


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
         <IonTitle size="small">{currentBook}</IonTitle>
        </IonToolbar>
      </IonHeader>
     
      <IonContent className="ion-padding" scrollEvents={true} fullscreen>
      {/*
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{currentBook}</IonTitle>
          </IonToolbar>
        </IonHeader>
      */}

        {
          (() => {
            if (name !== 'Home') {

              return <NTBook book={name} callback={setCurrentBook} />;
            }
            else{

              return <HomePage callback={setCurrentBook} />;

            }
          }

          )()
        }

       
      </IonContent>
    </IonPage>
  );
};

export default Page;
