
import { IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonContent } from '@ionic/react';
import { useEffect } from 'react';
import packageJSON from '../../package.json';
import './HomePage.css';

//+ packageJSON.version
export default function HomePage(props: { callback?: (chapterVerse: string) => void; }) {

    useEffect(() => {

        props.callback('OpenGreekNT');



    }, []);

    return <>

        <IonContent>
            <div className="container">
                <h1>Welcome to OpenGreekNT</h1>
                <p id="verseNumber">version {packageJSON.version}</p>
            </div>
            <IonCard>
                <IonCardHeader>
                    <IonCardTitle>
                        Morphology for all words
                    </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    <p>This project uses morphology produced by the <a target="_blank" rel="noreferrer" href="https://github.com/morphgnt">MorphGNT</a> project,
                        as well as the <a target="_blank" rel="noreferrer" href="https://sblgnt.com/">SBL Greek New Testament</a>, a textually modern and critically edited Greek text.</p>
                    <div className="imageContainer">
                        <img src="assets/images/morph.png" alt="Morphology" />
                    </div>
                </IonCardContent>
            </IonCard>
            <IonCard>
                <IonCardHeader>
                    <IonCardTitle>
                        Liddell & Scott Definitions
                    </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                    <p>Word definitions from Liddell & Scott (where applicable), along with glosses from <a target="_blank" rel="noreferrer" href="https://greeklexicon.org/lexicon/about/">John J. Dodson's public domain lexicon (2010).</a> </p>
                    <div className="imageContainer">
                        <img src="assets/images/lexicon.png" alt="Lexicon" />
                    </div>
                </IonCardContent>
            </IonCard>
        </IonContent>


    </>;

}