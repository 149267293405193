import { useState } from 'react';
import {  useIonModal } from '@ionic/react';

import WordInfoModalComponent from './WordInfoModalComponent';

import './W.css';


import dodson from '../data/dodsonv3.json';
import ls from '../data/ls.json';
export default function W(props: { children: string, "morph": string, "pos": string, "strongs"?: string, "stripped": string, "lemma": string}) {

    // const [present, dismiss] = useIonToast();
    const [wordInfo, setWordInfo] = useState('');
    const [highlight, setHighlight] = useState(false);

    const handleDismiss = () => {
        dis();
    };
    const [pres, dis] = useIonModal(<WordInfoModalComponent wordInfo={wordInfo} callback={handleDismiss} />, {
        onDismiss: handleDismiss
    });


    return <span onClick={WordClicked} className="word"> {props.children}</span>






    function WordClicked() {
       setHighlight(!highlight);
        const dataStripped = props.stripped;
        const dataLemma = props.lemma;
        const dataPOS = props.pos;
        const dataMorph = props.morph;
        const strongs = props.strongs;

        let dodsonDefinition = '';
        if (dodson[dataStripped] && dodson[dataStripped].meanings) {
            // console.log(dodson[dataStripped].meanings);
            dodsonDefinition = "<p>" + "<b>Gloss: </b>" + getCorrectDefenitionByStrongs(strongs, dodson[dataStripped].meanings) + "</p>";
            let lsEntry = '';
            if (ls[dataStripped]) {
                lsEntry = (ls[dataStripped].entry ? ls[dataStripped].entry : '');
            }
            setWordInfo('<p class="lemma">Lemma: ' + dataLemma + '</p><p class="pos">' + getPOS(dataPOS!) + ' - ' + getMorphString(dataMorph!) + '</p> ' + dodsonDefinition + (lsEntry === '' ? '' : '<div class="ls"><p class="ls-title">Liddell & Scott:</p>' + lsEntry + '</div>'));
    
    
        }
        else if (dodson[dataStripped]) {

            dodsonDefinition = "<p>" + "<b>Gloss: </b>" + dodson[dataStripped].definition + "</p>";
            let lsEntry = '';
            if (ls[dataStripped]) {
                lsEntry = (ls[dataStripped].entry ? ls[dataStripped].entry : '');
            }
            setWordInfo('<p class="lemma">Lemma: ' + dataLemma + '</p><p class="pos">' + getPOS(dataPOS!) + ' - ' + getMorphString(dataMorph!) + '</p> ' + dodsonDefinition + (lsEntry === '' ? '' : '<div class="ls"><p class="ls-title">Liddell & Scott:</p>' + lsEntry + '</div>'));
    
    

        }
        else {
            setWordInfo('<p>Error. Unable to get Word information. This is probably due to an orthographical variant or weird spelling. Please report this to eric@tentatio.com. Thanks.</p>')
        }


        //<div>test<IonButton expand="block" onClick={() => handleDismiss()}>Close</IonButton></div>
        pres();
        /*       present({
                   buttons: [{ text: 'hide', handler: () => dismiss() }],
                   message: dodsonDefinition + wordInfo,
                   onDidDismiss: () => { },
               });*/
        /*
           const dbref = firebase.database().ref(dataStripped || "").on('value', (snapshot) => {
           //const dbref = firebase.database().ref(dataStripped || '').orderByChild('greekFullWord').on('child_added', (snapshot) => {
               snapshot.forEach((item) => {
                   console.log("foreach");
                   console.log(item.val());
               })
               console.log(snapshot);
               let snapValue = snapshot.val();
          
               
               const wordInfo = 'Lemma: ' + dataLemma + '\n' + getPOS(dataPOS!) + '\n <b>' + getMorphString(dataMorph!) + '</b> \n' + snapValue['entry'];
               present({
                   buttons: [{ text: 'hide', handler: () => dismiss() }],
                   message: dodsonDefinition + wordInfo,
                   onDidDismiss: () => {},
               })
               //setStateTest(snapValue['entry']);
               //console.log(snapValue['entry']);
             },error => {
               console.error(error);
           });*/

    }

    function getCorrectDefenitionByStrongs(strongsNumber: string, definitions: []) {
        for (let i = 0; i < definitions.length; i++) {

            if (definitions[i]['strongs'] === strongsNumber) {
                return definitions[i]['definition'];
            }
        }

        return '';
    }

    function getMorphString(morph: string) {
        const morphArray = morph.split('');
        return getFirst(morphArray[0]) + ' ' + getSecond(morphArray[1]) + ' ' + getThird(morphArray[2]) + ' ' + getFourth(morphArray[3]) + ' ' + getFifth(morphArray[4]) + ' ' + getSixth(morphArray[5]) + ' ' + getSeventh(morphArray[6]) + ' ' + getEigth(morphArray[7]);
    }

    function getFirst(morphItem: string) {
        switch (morphItem) {
            case '1':
                return 'First Person';
            case '2':
                return 'Second Person';
            case '3':
                return 'Third Person';
            default:
                return '';
        }

    }
    function getSecond(morphItem: string) {

        switch (morphItem) {
            case 'P':
                return 'Present';
            case 'I':
                return 'Imperfect';
            case 'F':
                return 'future';
            case 'A':
                return 'Aorist';
            case 'X':
                return 'Perfect';
            case 'Y':
                return 'Pluperfect';
            default:
                return '';
        }

    }
    function getThird(morphItem: string) {
        switch (morphItem) {
            case 'A':
                return 'Active';
            case 'M':
                return 'Middle';
            case 'P':
                return 'Passive';
            default:
                return '';
        }

    }
    function getFourth(morphItem: string) {
        switch (morphItem) {
            case 'I':
                return 'Indicative';
            case 'S':
                return 'Subjuntive';
            case 'O':
                return 'Optative';
            case 'D':
                return 'Imperative';
            case 'N':
                return 'Infinitive';
            case 'P':
                return 'Participle';
            default:
                return '';
        }
    }
    function getFifth(morphItem: string) {

        switch (morphItem) {
            case 'N':
                return 'Nominative';
            case 'V':
                return 'Vocative';
            case 'G':
                return 'Genitive';
            case 'D':
                return 'Dative';
            case 'A':
                return 'Accusative';
            default:
                return '';
        }

    }
    function getSixth(morphItem: string) {
        switch (morphItem) {
            case 'S':
                return 'Singular';
            case 'P':
                return 'Plural';
            default:
                return '';
        }

    }
    function getSeventh(morphItem: string) {
        switch (morphItem) {
            case 'M':
                return 'Masculine';
            case 'F':
                return 'Feminine';
            case 'N':
                return 'Neuter';
            default:
                return '';
        }
    }
    function getEigth(morphItem: string) {
        switch (morphItem) {
            case 'C':
                return 'Comparative';
            case 'S':
                return 'Superlative';
            default:
                return '';
        }
    }

    function getPOS(pos: string) {

        switch (pos) {
            case "N-":
                return "Noun";
            case "V-":
                return "Verb";
            case "RA":
                return "Definite Article";
            case "RP":
                return "Personal Pronoun";
            case "C-":
                return "Conjunction";
            case "P-":
                return "Preposition";
            case "D-":
                return "Adverb";
            case "X-":
                return "Conjunction, Interrogative Pronoun, or Indeclinable Particle";
            case "I-":
                return "Interjection";
            case "A-":
                return "Adjective";
            case "RI":
                return "Indefinite Pronoun";
            case "RR":
                return "Relative Pronoun";
            case "RD":
                return "Demonstrative Pronoun";
            default:
                return '';
        }


    }

}