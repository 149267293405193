import { IonFab, IonFabButton, IonIcon } from '@ionic/react';
import { CSSTransition } from 'react-transition-group';
//import smoothscroll from 'smoothscroll-polyfill';

//import ReactCSSTransitionGroup from 'react-transition-group';

import { useState, useEffect } from 'react';
import chapterVerseList from '../data/chapters.json';
import './ChapterSelect.css';
//chapterVerse: { "chapter": string, "numberOfVerses": string }[]
//var ReactCSSTransitionGroup = require('react-transition-group');
export default function ChapterSelect(props: { name: string, currentChapter: number, callback?: (chapter: number) => void; }) {

    const [chapterNumber, setChapterNumber] = useState(0);

   

  
    const [triggerAnimation, setTriggerAnimation] = useState(false);

 



    useEffect(() => {//this will load the first time a book is selected.
        props.callback(0);
        const tmp = chapterVerseList[props.name]; //tmp becomes an array of [{chapter:, numberofverses:}] type.
        if (tmp) {

            setChapterNumber(tmp.length);
            
        }

    }, [props.name]);



    const handleClick = (e) => {
        
        props.callback(parseInt(e.target.innerText) - 1);

    }


    const removeHighlights = () => {
        let highlightedElements = document.getElementsByClassName('highlighted');
        let elArray = [];

        for (let i = 0; i < highlightedElements.length; i++) {
            elArray.push(highlightedElements.item(i));
        }
        for (let el of elArray) {
            el.classList.remove('highlighted');
        }

    }



    return <>
        <div id="chapterSelectionContainer"  /*className={'' + (isSelectionHidden ? "hidden" : "")} */ >
            <div id="chapterSelector">
                <div className="selectHeading">Chapter:</div>
                <br></br>
                <CSSTransition
                    classNames="chapterSelectAnimation"
                    // onEnter={() => }
                    // onExit={() => console.log("exited")}
                    in={triggerAnimation}
                    timeout={5}
                >
                    <p className="items-to-select">
                        {chapterVerseList[props.name].map((value, index) => {
                            return <span key={index} className={(props.currentChapter == index ? "chapterSelected" : "chapter-item")} onClick={handleClick}>{index + 1}</span>
                        })}
                    </p>
                </CSSTransition>

            </div>
        </div>
    </>
}