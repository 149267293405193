import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from '@ionic/react';

import { useLocation } from 'react-router-dom';
import {  bookOutline } from 'ionicons/icons';
import './Menu.css';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: 'Matthew',
    url: '/page/Matthew',

    iosIcon: bookOutline,
    mdIcon: bookOutline
  },
  {
    title: 'Mark',
    url: '/page/Mark',

    iosIcon: bookOutline,
    mdIcon: bookOutline
  },
  {
    title: 'Luke',
    url: '/page/Luke',

    iosIcon: bookOutline,
    mdIcon: bookOutline
  },
  {
    title: 'John',
    url: '/page/John',

    iosIcon: bookOutline,
    mdIcon: bookOutline
  },
  {
    title: 'Acts',
    url: '/page/Acts',

    iosIcon: bookOutline,
    mdIcon: bookOutline
  },
  {
    title: 'Romans',
    url: '/page/Romans',

    iosIcon: bookOutline,
    mdIcon: bookOutline
  },
  {
    title: 'First Corinthians',
    url: '/page/FirstCorinthians',

    iosIcon: bookOutline,
    mdIcon: bookOutline
  },
  {
    title: 'Second Corinthians',
    url: '/page/SecondCorinthians',

    iosIcon: bookOutline,
    mdIcon: bookOutline
  },
  {
    title: 'Galatians',
    url: '/page/Galatians',

    iosIcon: bookOutline,
    mdIcon: bookOutline
  },
  {
    title: 'Ephesians',
    url: '/page/Ephesians',

    iosIcon: bookOutline,
    mdIcon: bookOutline
  },
  {
    title: 'Philippians',
    url: '/page/Philippians',

    iosIcon: bookOutline,
    mdIcon: bookOutline
  },
  {
    title: 'Colossians',
    url: '/page/Colossians',

    iosIcon: bookOutline,
    mdIcon: bookOutline
  },
  {
    title: 'First Thessalonians',
    url: '/page/FirstThessalonians',

    iosIcon: bookOutline,
    mdIcon: bookOutline
  },
  {
    title: 'Second Thessalonians',
    url: '/page/SecondThessalonians',

    iosIcon: bookOutline,
    mdIcon: bookOutline
  },
  {
    title: 'First Timothy',
    url: '/page/FirstTimothy',

    iosIcon: bookOutline,
    mdIcon: bookOutline
  },
  {
    title: 'Second Timothy',
    url: '/page/SecondTimothy',

    iosIcon: bookOutline,
    mdIcon: bookOutline
  },
  {
    title: 'Titus',
    url: '/page/Titus',

    iosIcon: bookOutline,
    mdIcon: bookOutline
  },
  {
    title: 'Philemon',
    url: '/page/Philemon',

    iosIcon: bookOutline,
    mdIcon: bookOutline
  },
  {
    title: 'Hebrews',
    url: '/page/Hebrews',

    iosIcon: bookOutline,
    mdIcon: bookOutline
  },
  {
    title: 'James',
    url: '/page/James',

    iosIcon: bookOutline,
    mdIcon: bookOutline
  },
  {
    title: 'First Peter',
    url: '/page/FirstPeter',

    iosIcon: bookOutline,
    mdIcon: bookOutline
  },
  {
    title: 'Second Peter',
    url: '/page/SecondPeter',

    iosIcon: bookOutline,
    mdIcon: bookOutline
  },
  {
    title: 'First John',
    url: '/page/FirstJohn',

    iosIcon: bookOutline,
    mdIcon: bookOutline
  },
  {
    title: 'Second John',
    url: '/page/SecondJohn',

    iosIcon: bookOutline,
    mdIcon: bookOutline
  },
  {
    title: 'Third John',
    url: '/page/ThirdJohn',

    iosIcon: bookOutline,
    mdIcon: bookOutline
  },
  {
    title: 'Jude',
    url: '/page/Jude',

    iosIcon: bookOutline,
    mdIcon: bookOutline
  },
  {
    title: 'Revelation',
    url: '/page/Revelation',

    iosIcon: bookOutline,
    mdIcon: bookOutline
  }
];





const Menu: React.FC = () => {
  const location = useLocation();


  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>OpenGreekNT</IonListHeader>
  
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false} >
                  <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>

      </IonContent>
    </IonMenu>
  );
};

export default Menu;
