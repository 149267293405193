import { IonButton, IonContent } from "@ionic/react";
import './WordInfoModalComponent.css';
import parse from 'html-react-parser';

export default function WordInfoModalComponent(props: { wordInfo: string, callback: () => void }) {



    return <IonContent><div className = 'word-model'>{parse(props.wordInfo)}<IonButton fill="outline" expand="block" onClick={props.callback}>Close</IonButton></div></IonContent>;

   /* return <div id="entry">
        <div id="lemma">ἐρημία</div>
        <div id="full">ἐρημία, ἡ,</div>
        <div id="sense">of places, <b>a solitude, desert, wilderness</b>, <i><b>Hdt.</b></i>, <i><b>Aesch.</b></i>, etc.</div>
        <div id="sense">as a state or condition, <b>solitude, loneliness</b>, <i>ἐρημίαν ἄγειν, ἔχειν</i> to keep alone, <i><b>Eur.</b></i>; of persons, <b>isolation, desolation</b>, <i><b>Soph.</b></i>;  <i>διʼ ἐρημίαν</i> from <b>being left alone</b>, <i><b>Thuc.</b></i></div>
        <div id="sense">c. gen. <b>want of, absence</b>, <i><b>Eur.</b></i>, <i><b>Thuc.</b></i>, etc.;  <i>τὴν ἐρ. ὁρῶν τῶν κωλυσόντων</i> seeing that there would be <b>none</b> to hinder him, <i><b>Dem.</b></i>;  <i>ἐρ. κακῶν</i> <b>freedom from</b> evil, <i><b>Eur.</b></i></div>
    </div>*/

}